import React from 'react';




class privacy extends React.Component {

  render() {
    return (
      <>
        <section class="row experience-area" style={{ paddingBottom: '3%' }}>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 experience-info">
                <div class="row">

                  <div class="col-md-12" style={{ marginTop: '3%' }}>
                    <div class="content">
                      <h2 style={{ textAlign: 'center', margin: '-30px' }}>Privacy Policy</h2>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-sm-12 experience-info">

                <div class="sep-wrap element-animate element-animate-false center nz-clearfix" data-effect="none">
                  <div class="nz-separator solid" id="div_8767_1">&nbsp;</div>
                </div>
              </div>
              <div class="row">

                <div class="vc_col-lg-12">

                  <p><b>Global Trendz</b> is the owner of website “React Academy”.</p>
                  <p>Our privacy policy is designed to provide greater transparency into our privacy practices and principles
                    while processing your personal information.</p>
                  <p>Effective Date: 20th Feburary 2020</p>
                  <p>This privacy policy describes the treatment of certain Personal Information provided by you or collected
                    by us on http://reactacademy.in/ where this privacy policy is posted.</p>
                  <p>By accessing our Website, you are hereby consenting to collection of your information by us.The
                    information in some cases may include Personal Information and Sensitive Personal Information too. You
                    will be required to click on the box below, confirming your acceptance of this Privacy Policy.</p>

                  <h4>1. Legal basis for collecting information</h4>
                  <p>We collect certain Information because it is necessary for us to be able to gather the data required for
                    the website to function. We also collect certain Information to comply with specified statutory
                    requirements.</p>
                  <p>Such data is processed based on your consent. To the extent that processing is
                    carried out on the basis of consent, it is for you to give such a consent and you may
                    at any time, wholly or partly, withdraw your consent. Even if you have consented to
                    our using your information for a specific purpose, you will have the right to change
                    your mind at any time, but this will not affect any processing that has already taken
                    place.</p>

                  <h4>2. Type of Information that we collect:</h4>
                  <p>We collect the following information as and when you access and use the information as set out below:</p>
                  <p> Information you provide when you either book a stall or sign up for our visitor
                    registration: Information you provide when you book a stall or register as a
                    visitor including your first name and last name, contact information, email address, city, mobile number
                    etc and your photograph.</p>
                  <p> Information you provide when you use our website: Location information
                    (either provided by a mobile device through which you utilize our website or
                    associated with your IP address) when you use our website, usage, viewing
                    and technical data, including your device identifier or IP address, when you
                    use our website on third-party sites or platforms or open emails we send. The
                    information you provide in public forums while using our website;</p>
                  <p> Information you provide when you contact us for support: Information sent
                    either one-to-one or within a limited group using our message, chat, post or
                    similar functionality, where we are required by law to collect this information.</p>
                  <h4>3. How and Why We Collect Your Information</h4>
                  <p> We collect information through technology, such as cookies and web beacons
                    when you use our website. The information we learn from customers helps us
                    personalize and continually improve your experience of the Services as set
                    out below:</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Provide you with notifications via email and
                    within the information
                    based on your notification selections;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Contact you by email, postal mail, or phone
                    regarding services,
                    surveys, research studies, promotions, special events and other
                    subjects that we think may be of interest to you;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Help us better understand your interests and
                    needs, and improve the
                    utility;</p>

                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Provide, manage, and improve the utility;</p>
                  <p> In addition to the above, we process your Personal Information to prevent,
                    detect and investigate potential prohibited or illegal activities, including fraud,
                    and to enforce our terms of use or for any other offer or marketing campaign
                    as the case may be.</p>

                  <h4>4. Sharing with Third-Party</h4>
                  <p>We do not share your information with any third parties unless required to do so by
                    any statutory authorities under any law in force. However, we disclose certain
                    information to some third parties as duly displayed on our website.</p>

                  <p>In addition, if you access our website through any intermediate website, the cookies
                    of such website may be accessing certain information at the source level over which
                    we have no control.</p>

                  <h4>5. Information that cannot be uploaded:</h4>

                  <p>While using the website, you shall not host, display, upload, modify, publish, transmit,
                    update or share any information that:</p>

                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> belongs to another person and to which you do
                    not have any right to;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> is grossly harmful, harassing, blasphemous
                    defamatory, obscene,
                    pornographic, pedophilic, libelous, invasive of another&#39;s privacy, hateful, or
                    racially, ethnically objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner whatever;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> harms minors in any way;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> infringes any patent, trademark, copyright or
                    other proprietary rights;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> violates any law for the time being in force;
                  </p>
                  <p> <i class="fa fa-angle-double-right" aria-hidden="true"></i>deceives or misleads the addressee about the
                    origin of such messages or
                    communicates any information which is grossly offensive or menacing in
                    nature;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> impersonates another person;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> contains software viruses or any other
                    computer code, files or programs
                    designed to interrupt, destroy or limit the functionality of any computer
                    resource;</p>
                  <p>threatens the unity, integrity, defense, security or sovereignty of India, friendly
                    relations with foreign states, or public order or causes incitement to the
                    commission of any cognizable offence or prevents investigation of any
                    offence or is insulting any other nation.</p>
                  <h4>6. Your Controls and Choices:</h4>
                  <p>You can control your choices regarding our collection, use and sharing of your
                    information. In accordance with local law, your controls and choices may include:</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> You may correct, update and delete your
                    account;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> You may change your choices for product
                    emails and alerts;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> You may request access to the personal
                    information we hold about you and
                    that we shall amend or delete it and we shall request third parties with whom
                    we have shared the information do the same. Once you place a request to
                    delete the personal information, we shall take all reasonable steps taking into
                    account available technology and the means available to us, including
                    technical measures to erase the personal information.</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> You may choose whether we share your personal
                    information with other
                    companies so they can send you offers and promotions about their products
                    and services;</p>
                  <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> You may choose whether to receive targeted
                    advertising from many ad
                    websites, data exchanges, marketing analytics and other service providers;</p>



                  <h4>E-mail address: reactacademy@globaltrendz.com</h4>
                  <p>You may exercise your controls and choices, or request access to your personal
                    information, by contacting inquiry@infairs.in, or following instructions provided in
                    communications sent to you. You may request access to the personal information we
                    hold about you and that we amend or delete it and we request third parties with
                    whom we have shared the information do the same. Once you place a request to
                    delete the personal information, we shall take all reasonable steps taking into
                    account available technology and the means available to us, including technical
                    measures to erase the personal information. If you have questions regarding the
                    specific personal information about you that we process or retain, please send your
                    grievance at our email address.</p>

                  <p>You agree to receive marketing information from us. If you do not want us to process
                    your Personal Information for direct marketing, you can give written notice thereof to
                    us.</p>

                  <h4>7. How long is the data kept?</h4>

                  <p>We will keep Personal Information regarding you only as long as it is necessary,
                    having regard to the purpose of the processing. This means that Personal
                    Information collected and processed will be stored for as long as you have an
                    account and required by law.</p>

                  <p>We will also, during the same period, keep your account information, we may store
                    data for longer if required by law or if required in our legal interests, for example if
                    there are ongoing legal proceedings.</p>


                  <h4>8. Data Security and Integrity</h4>

                  <p>The security, integrity and confidentiality of your Personal Information are extremely
                    important to us. We have implemented technical, administrative and physical security
                    measures that are designed to protect guest information from unauthorized access,
                    disclosure, use and modification. From time to time, we review our security
                    procedures to consider appropriate new technology and methods. Please be aware
                    though that, despite our best efforts, no security measures are perfect or
                    impenetrable. We limit access to personal information about you to employees who
                    we believe reasonably need to come into contact with that information to be provided.</p>

                  <p>However, we may be required to share the aforesaid Personal Information with
                    government authorities and agencies for the purposes of verification of identity or for
                    prevention, detection, investigation including cyber incidents, prosecution and
                    punishment of offences. You agree and consent for us to disclose your information, if
                    so required under the applicable law.</p>


                  <h4>9. Changes to this Privacy Policy</h4>
                  <p>From time to time, we may change this privacy policy to accommodate new
                    technologies, industry practices, regulatory requirements or for other purposes. We
                    shall provide notice to you through the contact details provided to us by you.</p>

                  <h4>10. Comments and Questions</h4>

                  <p>If you have a comment or question about this privacy policy, please contact us
                    at inquiry@infairs.in. Our website may contain links to other sites not owned or
                    controlled by us and we are not responsible for the privacy practices of those sites.
                    We encourage you to be aware when you leave our Services and to read the privacy
                    policies of other sites that may collect your personal information.</p>

                  <h4>Definitions:-</h4>
                  <p>Anonymous Information: Anonymous information means information that does not
                    directly or indirectly identify, and cannot reasonably be used to identify, an individual
                    guest.</p>

                  <p>IP address: An IP address is associated with the access point through which you
                    enter the Internet, and is typically controlled by your Internet Service Provider (ISP),
                    your company, or your university. We may use IP addresses to collect information
                    regarding the frequency with which our guests visit various parts of our products
                    hosting our Services, and we may combine IP addresses with personal information.</p>

                  <p>Notices: Notices will be sent by email to you at the last email address you provided
                    us, by posting notice of such changes in our website, or by other means, consistent
                    with applicable law.</p>

                  <p>Personal information: Personal information means and includes information that
                    identifies (whether directly or indirectly) and Sensitive Personal Information of a
                    person by reference to an identifier such as a name, an identification number,
                    location data including postal address or an online identifier to one or more factors
                    specific to the physical, physiological, genetic, mental, economic, cultural or social
                    identity of such person. When anonymous information is directly or indirectly
                    associated with personal information, this anonymous information also is treated as
                    personal information.</p>

                  <p>Sensitive Personal Information: Sensitive Personal Information shall have the
                    meaning ascribed to it under the applicable law and is presently defined to mean
                    such personal information which consists of information relating to (i) passwords; (ii)
                    financial information such as bank accounts or credit card or debit card or other
                    payment instrument details; (iii) physical, physiological and mental health conditions;
                    (iv) sexual orientation; (v) medical records and history; (vi) Biometric information; (vii)
                    any detail relating to the above clauses as provided to body corporate for providing
                    service; and (viii) any of the information received under above clauses by body
                    corporate for processing, stored or processed under lawful contract or otherwise;
                    provided that any information that is freely available or accessible in public domain or
                    furnished under the Right to Information Act, 2000 or any other law for the time being
                    in force shall not be regarded as sensitive personal information.</p>







                </div>

              </div>
            </div>
          </div>
        </section>
      </>

    )
  }
}
export default privacy;



