import React from 'react';
import logo from './logo.svg';
import './App.css';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';


class Reactjs extends React.Component {

  render() {
    return (
      <div>
        <MetaTags>
          <title>
            ReactJs - React Academy | Training Institute for ReactJS & React
            Native in Delhi
          </title>
          <meta
            id="meta-description"
            name="description"
            content="Some description.At React Academy we provide best react js training course for Students, 
            Corporates and Developers who want to enhance their knowledge.  "
          />
        </MetaTags>
        <div class="site com-sppagebuilder view-page no-layout no-task itemid-437 en-gb ltr  sticky-header layout-fluid off-canvas-menu-init">
          <div class="off-canvas-menu-wrap">
            <div class="body-innerwrapper">
              <div class="sp-pre-loader">
                <div class="sp-loader-audio-wave"></div>
              </div>
              <header id="sp-header" className="animated menu-fixed">
                <div>
                  <section id="sp-top-bar">
                    <div>
                      <div class="row">
                        <div id="sp-top1" class="col-sm-12 col-md-12">
                          <div class="sp-column ">
                            <div class="col-md-4">
                              <ul
                                class="sp-contact-info"
                                style={{ float: "left" }}
                              >
                                <li
                                  class="sp-contact-address"
                                  style={{ marginTop: "4px" }}
                                >
                                  <i class="fa fa-map-marker"></i>
                                  New Delhi
                                  <span class="tooltiptext">
                                    <h4 class="locationhead">
                                      React Academy
                                    </h4>
                                    <p>
                                      D - 200, Third Floor,Mansarover
                                      Garden,
                                      <br /> New Delhi - 110015. INDIA
                                    </p>
                                    <p>
                                      {" "}
                                      <a
                                        href="https://www.google.com/maps/search/global+trendz/@28.6273581,77.1891295,12z/data=!3m1!4b1"
                                        target="_blank"
                                        style={{ color: "black" }}
                                      >
                                        <i class="fa fa-map-marker"></i> Get
                                        Direction
                                      </a>
                                    </p>
                                  </span>
                                </li>
                                <li
                                  class="sp-contact-address"
                                  style={{ marginTop: "4px" }}
                                >
                                  <i class="fa fa-map-marker"></i>
                                  Chennai
                                  <span class="tooltiptext2">
                                    <h4 class="locationhead">
                                      React Academy
                                    </h4>
                                    <p>
                                      621, 3rd Floor, Anna Salai, Chennai -
                                      600 006. INDIA.
                                    </p>
                                    <p>
                                      {" "}
                                      <a
                                        href="https://www.google.com/maps/place/Global+Trendz/@13.0544807,80.2504224,17z/data=!3m1!4b1!4m5!3m4!1s0x3a526646bfbd48bb:0xa13ada1206a37d27!8m2!3d13.0544807!4d80.2526111"
                                        target="_blank"
                                        style={{ color: "black" }}
                                      >
                                        <i class="fa fa-map-marker"></i> Get
                                        Direction
                                      </a>
                                    </p>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-8">
                              <ul class="sp-contact-info hidemob">
                                <li class="sp-contact-phone">
                                  <i
                                    class="fa fa-mobile"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  <a href="tel:917603999622">
                                    +91 76039 99622
                                  </a>
                                </li>
                                <li class="sp-contact-phone">
                                  <i class="fa fa-phone"></i>{" "}
                                  <a href="tel:+91-11-4703 7722">
                                    +91 11 4703 7722
                                  </a>
                                </li>
                                <li class="sp-contact-email">
                                  <i class="fa fa-envelope"></i>{" "}
                                  <a href="mailto:reactacademy@globaltrendz.com">
                                    reactacademy@globaltrendz.com
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.facebook.com/reactacademy.in"
                                    target="_blank"
                                  >
                                    <i class="fa fa-facebook fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.instagram.com/reactacademy.in"
                                    target="_blank"
                                  >
                                    <i class="fa fa-instagram fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.linkedin.com/company/thereactacademy"
                                    target="_blank"
                                  >
                                    <i class="fa fa-linkedin fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://twitter.com/thereactacademy"
                                    target="_blank"
                                  >
                                    <i class="fa fa-twitter fan"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div class="row">
                    <div id="sp-logo" class="col-xs-6 col-sm-3 col-md-2">
                      <div class="sp-column ">
                        <h1 class="logo">
                          <a href="/">
                            <img
                              class="sp-default-logo"
                              src="logos/logos/REACT ACADEMY.png"
                              alt="reactacademy logo"
                              style={{ width: "60%" }}
                            />
                          </a>
                        </h1>
                      </div>
                    </div>
                    <div id="sp-menu" class="col-xs-6 col-sm-9 col-md-10">
                      <div class="sp-column ">
                        <div class="sp-megamenu-wrapper">
                          {" "}
                          <a id="offcanvas-toggler" href="#">
                            <i class="fa fa-bars"></i>
                          </a>
                          <ul class="sp-megamenu-parent menu-fade hidden-sm hidden-xs">
                            <li class="sp-menu-item sp-has-child current-item active">
                              <a href="/" title="Main page">
                                Home
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a href="/#academy" title="Who We Are">
                                The Academy
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a
                                href="/#trainingprocess"
                                title="How We Teach"
                              >
                                Training Process
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a href="/#react" title="Technology">
                                React
                              </a>
                            </li>
                            <li class="sp-menu-item">
                              <a href="/#course" title="What We Offer">
                                Courses
                              </a>
                            </li>

                            <li class="sp-menu-item sp-has-child">
                              <a
                                href="/#prerequisites"
                                title="Prior Requirments"
                              >
                                Prerequisites
                              </a>
                            </li>

                            <li class="sp-menu-item">
                              <a href="/contact" title="Get In Touch">
                                Contact
                              </a>
                            </li>
                            <li>
                              <a href="/enroll">
                                <button class="btn btn-danger">
                                  Enroll Now
                                </button>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <section id="sp-page-title" className="contactsection">
                <div class="row">
                  <div id="sp-title" class="col-sm-12 col-md-12">
                    <div class="sp-column ">
                      <div
                        class="sp-page-title"
                        style={{
                          backgroundImage:
                            "url(images/banners/react-banner-1.jpg)",
                        }}
                      >
                        <div class="container-fluid">
                          <div class="breadheads">
                            <h2>React JS - Course</h2>
                            <ol class="breadcrumb">
                              <li>
                                <a href="/" class="pathway">
                                  Home
                                </a>
                              </li>
                              <li>
                                <a href="/#course">Course</a>
                              </li>
                              <li class="active">React JS</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="sp-main-body">
                <div class="row">
                  <div id="sp-component" class="col-sm-12 col-md-12">
                    <div class="sp-column ">
                      <div id="system-message-container"></div>
                      <div
                        id="sp-page-builder"
                        class="sp-page-builder  page-6"
                      >
                        <div class="page-content">
                          <section
                            id="section-id-1577083394"
                            class="sppb-section"
                            style={{ marginBottom: "30px" }}
                          >
                            <div class="container-fluid">
                              <div class="sppb-row">
                                <div class="sppb-col-sm-12">
                                  <div
                                    id="column-id-1577083395"
                                    class="sppb-addon-container"
                                  >
                                    <div
                                      id="sppb-addon-1577083396"
                                      class="clearfix"
                                    >
                                      <div className="row">
                                        <div className="col-md-9">
                                          <div class="sppb-addon sppb-addon-text-block sppb-text-left ">
                                            <div
                                              class="vs_heading tCenter "
                                              style={{ marginTop: "50px" }}
                                            >
                                              <h2 class="">
                                                Course Overview
                                              </h2>
                                              <p class="aboutus">
                                                Get a Kick Start for your
                                                Career as a react developer
                                                with our in-depth ReactJS
                                                Course
                                              </p>

                                              <div class="icon_heading">
                                                {" "}
                                                <span></span>
                                                <i
                                                  class="fa fa-dot-circle-o"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#ED1F24",
                                                    fontSize: "20px",
                                                  }}
                                                ></i>
                                                <span></span>
                                              </div>
                                            </div>

                                            <div class="col-md-12">
                                              <div
                                                class="sppb-addon-content"
                                                style={{ marginTop: "3%" }}
                                              >
                                                <p>
                                                  Learn{" "}
                                                  <b>
                                                    React JS Training Course
                                                  </b>{" "}
                                                  from React Academy that
                                                  provides results and
                                                  job-oriented training.
                                                  There is a huge demand for
                                                  developers with skills to
                                                  develop applications for
                                                  Organizations. React JS is
                                                  today the most trending
                                                  technology that companies
                                                  are adopting to develop
                                                  these applications.
                                                </p>
                                                <p>
                                                  Our course on React JS
                                                  covers a right from the
                                                  fundamentals to in depth
                                                  training of the React
                                                  Framework and
                                                  Architecture. You will
                                                  experience a state of the
                                                  art training process along
                                                  with real time projects to
                                                  give you a hands-on
                                                  experience of developing
                                                  applications using React.
                                                  Our course also shapes you
                                                  to build your career as a
                                                  professional React
                                                  Developer.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/*-------------------- Key feature section ----------------*/}
                                        <div className="col-md-9">
                                          <section class="keyfeature coursefeatures2">
                                            <div className="container-fluid">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div
                                                    class="vs_heading tCenter "
                                                    style={{
                                                      marginTop: "50px",
                                                    }}
                                                  >
                                                    <h2 class="">
                                                      Key Features
                                                    </h2>

                                                    <div class="icon_heading">
                                                      {" "}
                                                      <span></span>
                                                      <i
                                                        class="fa fa-dot-circle-o"
                                                        aria-hidden="true"
                                                        style={{
                                                          color: "#ED1F24",
                                                          fontSize: "20px",
                                                        }}
                                                      ></i>
                                                      <span></span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="row"
                                                    style={{
                                                      marginTop: "44px",
                                                    }}
                                                  >
                                                    <div class="col-md-12">
                                                      <ul>
                                                        <li
                                                          class="courseheading"
                                                          style={{
                                                            paddingLeft:
                                                              "15px",
                                                          }}
                                                        >
                                                          Duration : 7 Days
                                                        </li>
                                                      </ul>
                                                      <div
                                                        class="row"
                                                        style={{
                                                          marginTop: "2%",
                                                        }}
                                                      >
                                                        <div class="col-md-4">
                                                          <div
                                                            class="panel-body"
                                                            style={{
                                                              marginLeft:
                                                                "14px",
                                                            }}
                                                          >
                                                            <i
                                                              class="fa fa-book"
                                                              style={{
                                                                color:
                                                                  "#ED1F24",
                                                              }}
                                                            ></i>{" "}
                                                            &nbsp;In-depth
                                                            Training
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                          <div class="panel-body">
                                                            <i
                                                              class="fa fa-laptop"
                                                              style={{
                                                                color:
                                                                  "#ED1F24",
                                                              }}
                                                            ></i>
                                                            &nbsp;Real Time
                                                            Project
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                          <div
                                                            class="panel-body"
                                                            style={{
                                                              marginRight:
                                                                "14px",
                                                            }}
                                                          >
                                                            <i
                                                              class="fa fa-desktop"
                                                              style={{
                                                                color:
                                                                  "#ED1F24",
                                                              }}
                                                            ></i>{" "}
                                                            &nbsp;2 Months
                                                            Optional
                                                            Internship*
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <p class="coursepara2">
                                                    * The internship is
                                                    optional and can be
                                                    availed based on the
                                                    availability. This would
                                                    be a Non Paid Internship
                                                    for students who want to
                                                    get real time work
                                                    experience in developing
                                                    real time projects.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </div>

                                        {/*-------------------- Key feature section End ----------------*/}

                                        <div className="col-md-3">
                                          <div className="courseblogcontent">
                                            <div class="vs_heading tCenter ">
                                              <h3
                                                class=""
                                                style={{ color: "#ED1F24" }}
                                              >
                                                Other Courses
                                              </h3>

                                              <div class="icon_heading">
                                                {" "}
                                                <span></span>
                                                <i
                                                  class="fa fa-dot-circle-o"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#ED1F24",
                                                    fontSize: "20px",
                                                  }}
                                                ></i>
                                                <span></span>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div class="course-thumb courseblog">
                                                <img
                                                  src="images/react-advance.png"
                                                  alt="ReactJs"
                                                  className="phonecourse"
                                                  style={{ margin: "auto" }}
                                                />
                                              </div>
                                            </div>
                                            <h3 class="courseheading">
                                              React JS + NODE Advanced
                                            </h3>
                                            <p>
                                              Want to become a React JS
                                              Developer? Join our React JS
                                              Advanced course!
                                            </p>
                                            <ul>
                                              <li class="courseheading">
                                                Duration : 15 Days
                                              </li>
                                              <ul class="cours2">
                                                <li class="coursetrin">
                                                  In-depth Training{" "}
                                                </li>
                                                <li class="coursetrin">
                                                  Real Time Project{" "}
                                                </li>
                                                <li class="coursetrin">
                                                  2 Months Optional
                                                  Internship*
                                                </li>
                                              </ul>
                                            </ul>

                                            <div class="coursebtnsec">
                                              <a href="/react-js-advanced-course">
                                                {" "}
                                                <button class="btn btn-danger">
                                                  Course Details
                                                </button>
                                              </a>
                                              <a
                                                href="/enroll"
                                                id="prerequisites"
                                              >
                                                <button class="btn btn-danger coursebtn">
                                                  Enroll Now
                                                </button>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section class="skills">
                            <div class="container">
                              <div
                                class="vs_heading tCenter "
                                style={{ marginTop: "50px" }}
                              >
                                <h2 class="">Skills Covered</h2>
                                <div class="icon_heading">
                                  {" "}
                                  <span></span>
                                  <i
                                    class="fa fa-dot-circle-o"
                                    aria-hidden="true"
                                    style={{
                                      color: "#ED1F24",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                  <span></span>
                                </div>
                              </div>
                              <div class="row" style={{ marginTop: "3%" }}>
                                <div class="skillcontents2">
                                  <div class="col-md-4">
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          React Introduction
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Why React ?</li>
                                        <li>
                                          Node JS, Node Package Manager
                                        </li>
                                        <li>Advantages of React</li>
                                      </ul>
                                    </ul>
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Environment Set Up
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Environment set up</li>
                                        <li>Create React App</li>
                                        <li>JSX</li>
                                      </ul>
                                    </ul>

                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Component Types
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Type of Components</li>
                                        <li>HOC</li>
                                        <li>Pure Components</li>
                                        <li>Props</li>
                                        <li>State</li>
                                      </ul>
                                    </ul>

                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          LifeCycle Methods & Hooks
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Lifecycle methods</li>
                                        <li>React Hooks</li>
                                      </ul>
                                    </ul>
                                  </div>
                                  <div class="col-md-4">
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Context API
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Why context API?</li>
                                        <li>
                                          Creating context using
                                          createContext()
                                        </li>
                                        <li>
                                          Using providers and consumers
                                        </li>
                                        <li>Updating Context</li>
                                      </ul>
                                    </ul>
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          React Forms & Events
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>PropTypes</li>
                                        <li>React Forms</li>
                                        <li>Event Listeners</li>
                                      </ul>
                                    </ul>

                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Routing with React
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>React Router DOM</li>
                                        <li>Routing using Hooks</li>
                                      </ul>
                                    </ul>
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Error Handling
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>
                                          Handling errors using try catch
                                        </li>
                                      </ul>
                                    </ul>

                                    {/* <ul class="courseskills">
                                                                            <li><span class="headingreact2">API Calls Integration</span>
                                                                            </li>
                                                                            <ul class="coursehe">
                                                                                <li>Calling REST API using fetch()</li>
                                                                                <li>Using axios</li>
                                                                                <li>Difference b/w fetch & axios</li>

                                                                            </ul>



                                                                        </ul> */}
                                    {/* <ul class="courseskills">

                                                                            <li><span class="headingreact2">Database Integration</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Integration with SQL </li>

                                                                            </ul>
                                                                        </ul> */}
                                    {/* <ul class="courseskills">

                                                                            <li><span class="headingreact2">Introduction to Redux</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Redux Architecture</li>
                                                                                <li>Fundamentals like Actions & Reducers</li>
                                                                                <li>Centralized Store</li>


                                                                            </ul>
                                                                        </ul>

                                                                        <ul class="courseskills">
                                                                            <li><span class="headingreact2"> Middlewares Introduction</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Redux Thunk</li>
                                                                                <li>Redux Saga</li>
                                                                                <li>Difference b/w Thunk & Saga</li>

                                                                            </ul>
                                                                        </ul>

                                                                        <ul class="courseskills">
                                                                            <li><span class="headingreact2">React Best Practices</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>State & Props De-Fragmentation</li>
                                                                                <li>Redux Folder Naming Convention</li>
                                                                                <li>Business Logic placeholder</li>

                                                                            </ul>
                                                                        </ul>
                                                                        <ul class="courseskills">
                                                                            <li><span class="headingreact2">Unit Testing</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Why Unit Testing ?</li>
                                                                                <li>Advantages of UT</li>
                                                                                <li>JEST</li>
                                                                                <li>Mocha & Chai</li>

                                                                            </ul>
                                                                        </ul>
                                                                        <ul class="courseskills">
                                                                            <li><span class="headingreact2">Advanced React Tools</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Intro to the React Dev tools</li>
                                                                                <li>Debugging Extension</li>

                                                                                <li>Analyzing performance with react profiler</li>

                                                                            </ul>




                                                                        </ul> */}
                                  </div>
                                  <div class="col-md-4">
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Mini Projects
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>Mini project hands On</li>
                                        <li>
                                          Individual topic demo's and code
                                          snippets
                                        </li>
                                        <li>
                                          GIT access for code demonstrations
                                        </li>
                                      </ul>
                                    </ul>

                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Getting Started with Node
                                        </span>{" "}
                                      </li>
                                      <ul class="coursehe">
                                        <li>What is Node.js ?</li>
                                        <li>Basics of Node Development</li>
                                        <li>User Input Validation</li>
                                      </ul>
                                    </ul>

                                    {/* <ul class="courseskills">
                                                                            <li><span class="headingreact2">Express.js Framework</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Why Express.js ?</li>
                                                                                <li>Advantages of Express Framework</li>
                                                                            </ul>

                                                                        </ul>
                                                                        <ul class="courseskills">
                                                                            <li><span class="headingreact2">REST API's using Express</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Advanced Routes and Models</li>
                                                                                <li>Async Requests</li>
                                                                                <li>Pure Components</li>
                                                                                <li>MVC Structure</li>

                                                                            </ul>
                                                                        </ul> */}

                                    {/* <ul class="courseskills">
                                                                            <li><span class="headingreact2">GraphQL Integration</span></li>
                                                                            <ul class="coursehe">
                                                                                <li>Integrating REST with GraphQL</li>

                                                                            </ul> 
                                                                        </ul>  */}
                                    <ul class="courseskills">
                                      <li>
                                        <span class="headingreact2">
                                          Live Project
                                        </span>
                                      </li>
                                      <ul class="coursehe">
                                        <li>
                                          Real time live project for
                                          students with completion
                                          certificates
                                        </li>
                                      </ul>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section
                            id="section-id-1577083363"
                            class="sppb-section  event_row"
                          >
                            <div class="container-fluid">
                              <div class="sppb-row">
                                <div class="sppb-col-sm-6">
                                  <div
                                    id="column-id-1577083364"
                                    class="sppb-addon-container"
                                  >
                                    <div
                                      id="sppb-addon-1577083365"
                                      class="clearfix"
                                    >
                                      <div class="course_countdown">
                                        <div class="info">
                                          <h3>
                                            <a href="#">
                                              Become a Full stack React
                                              Developer{" "}
                                            </a>
                                          </h3>
                                          <span class="author">
                                            Are you ready to Learn React?
                                          </span>
                                          <h2>Register yourself now</h2>
                                          <div class="des">
                                            <p>
                                              Hurry...! join the React
                                              Bootcamp session that suits
                                              you the best. Our new sessions
                                              begin every week.
                                            </p>
                                          </div>
                                        </div>
                                        <div class="time">
                                          <ul style={{ paddingLeft: "5%" }}>
                                            <h1
                                              style={{ color: "#ED1F24" }}
                                            >
                                              Call{" "}
                                              <a href="tel:917603999622">
                                                +91 76039 99622
                                              </a>
                                            </h1>
                                          </ul>
                                        </div>
                                        <div class="more">
                                          {" "}
                                          <a href="/enroll">Enroll Now</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="sppb-col-sm-6">
                                  <div
                                    id="column-id-1577083366"
                                    class="sppb-addon-container"
                                  >
                                    <div
                                      id="sppb-addon-1577083367"
                                      class="clearfix"
                                    >
                                      <div class="sppb-addon sppb-addon-single-image sppb-text-left ">
                                        <div class="sppb-addon-content">
                                          <div class="sppb-addon-single-image-container">
                                            <div class="row">
                                              <div
                                                id="sppb-addon-1577083391"
                                                class="clearfix"
                                              >
                                                <div class="vs_heading tCenter prequist">
                                                  <h2
                                                    class=""
                                                    style={{
                                                      color: "white",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    Prerequisites
                                                  </h2>
                                                  <p
                                                    class="aboutus"
                                                    style={{
                                                      textAlign: "right",
                                                      color: "white",
                                                    }}
                                                  >
                                                    A few prerequisites
                                                    before you join our
                                                    Bootcamp Sessions.
                                                  </p>
                                                  <div class="icon_heading prehrline">
                                                    {" "}
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          " white",
                                                      }}
                                                    ></span>
                                                    <i
                                                      class="fa fa-dot-circle-o"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ED1F24",
                                                        fontSize: "20px",
                                                      }}
                                                    ></i>
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          "white",
                                                      }}
                                                    ></span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">
                                                  Knowledge
                                                </h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        "right!important",
                                                    }}
                                                  >
                                                    You must have a good
                                                    knowledge of HTML5, CSS
                                                    & JavaScript and a
                                                    little experience in
                                                    developing Web Apps
                                                    would be an added
                                                    advantage.
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">
                                                  Personal Laptop
                                                </h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        "right!important",
                                                    }}
                                                  >
                                                    You must bring your
                                                    personal laptop along
                                                    with you. This is
                                                    because as part of the
                                                    training we would want
                                                    you to develop
                                                    applications for testing
                                                    and deployment.
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">
                                                  Setup on your Laptop
                                                </h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        "right!important",
                                                    }}
                                                  >
                                                    A latest version of the
                                                    React Libraries, Node JS
                                                    and Visual Studio Code
                                                    is what you would need.
                                                    On Enrollment you will
                                                    receive a list of things
                                                    to be installed.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="sp-cmc">
                <div class="container">
                  <div class="row">
                    <div id="sp-feature" class="col-sm-12 col-md-12">
                      <div class="sp-column ">
                        <div class="sp-module ">
                          <h2>
                            Our Training & Internships Empowers your React
                            Skills
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <footer id="sp-footer" className="webfooter">
                <div class="container-fluid">
                  <div class="row">
                    <div id="sp-footer1" class="col-sm-6 col-md-6">
                      <div class="sp-column tLeft">
                        <span class="sp-copyright">
                          ©{" "}
                          <script type="text/javascript">
                            var year = new
                            Date();document.write(year.getFullYear());
                          </script>{" "}
                          React Academy - A subsidiary of{" "}
                          <a href="globaltrendz.com">Global Trendz</a>. All
                          Rights Reserved
                        </span>
                      </div>
                    </div>
                    <div id="sp-footer2" class="col-sm-6 col-md-6">
                      <div class="sp-column tRight">
                        <ul class="social-icons">
                          <li>
                            <a
                              href="https://www.facebook.com/reactacademy.in"
                              target="_blank"
                            >
                              <i class="fa fa-facebook fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/reactacademy.in"
                              target="_blank"
                            >
                              <i class="fa fa-instagram fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/thereactacademy"
                              target="_blank"
                            >
                              <i class="fa fa-linkedin fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/thereactacademy"
                              target="_blank"
                            >
                              <i class="fa fa-twitter fan"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
              <footer id="sp-footer" className="mobfooter">
                <div class="container-fluid">
                  <div class="row">
                    <div id="sp-footer2" class="col-sm-6 col-md-6">
                      <div class="sp-column tRight">
                        <ul class="social-icons">
                          <li>
                            <a
                              href="https://www.facebook.com/reactacademy.in"
                              target="_blank"
                            >
                              <i class="fa fa-facebook fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/reactacademy.in"
                              target="_blank"
                            >
                              <i class="fa fa-instagram fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/thereactacademy"
                              target="_blank"
                            >
                              <i class="fa fa-linkedin fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/thereactacademy"
                              target="_blank"
                            >
                              <i class="fa fa-twitter fan"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="sp-footer1" class="col-sm-6 col-md-6">
                      <div class="sp-column tLeft">
                        <span class="sp-copyright">
                          ©{" "}
                          <script type="text/javascript">
                            var year = new
                            Date();document.write(year.getFullYear());
                          </script>{" "}
                          React Academy - A subsidiary of{" "}
                          <a href="globaltrendz.com">Global Trendz</a>.
                          <br /> All Rights Reserved
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>

          <div class="offcanvas-menu">
            {" "}
            <a href="#" class="close-offcanvas">
              <i class="fa fa-remove"></i>
            </a>
            <div class="offcanvas-inner">
              <div class="sp-module ">
                <div class="sp-module-content">
                  <ul class="nav menu ">
                    <li class="item-437  current active deeper parent">
                      <a href="/" title="Main page">
                        {" "}
                        Home
                      </a>
                    </li>
                    <li class="item-518  deeper parent ">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass(
                            "offcanvas"
                          );

                          window.location.href = "/#academy";
                        }}
                        title="Who We Are"
                      >
                        {" "}
                        The Academy
                      </a>
                    </li>

                    <li class="item-520">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass(
                            "offcanvas"
                          );

                          window.location.href = "/#trainingprocess";
                        }}
                        // href="/#trainingprocess"
                        title="How We Teach"
                      >
                        {" "}
                        Training Process
                      </a>
                    </li>
                    <li class="item-522  deeper parent">
                      <a
                        title="Technology"
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass(
                            "offcanvas"
                          );

                          window.location.href = "/#react";
                        }}
                      >
                        {" "}
                        React
                      </a>
                    </li>
                    <li class="item-521  deeper parent">
                      <a
                        title="What We Offer"
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass(
                            "offcanvas"
                          );

                          window.location.href = "/#course";
                        }}
                      >
                        {" "}
                        Courses
                      </a>
                    </li>
                    <li class="item-528  deeper parent">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass(
                            "offcanvas"
                          );

                          window.location.href = "/#prerequisites";
                        }}
                        title="PRIOR REQUIRMENTS"
                      >
                        Prerequisites
                      </a>
                    </li>

                    <li class="item-584">
                      <a href="/contact" title="Get In Touch">
                        {" "}
                        Contact
                      </a>
                    </li>

                    <li class="sp-contact-phone">
                      <a href="tel:917603999622">+91 76039 99622</a>
                    </li>
                    <li class="sp-contact-phone">
                      {" "}
                      <a href="tel:+91-11-4703 7722">+91 11 4703 7722</a>
                    </li>
                    <li class="sp-contact-email">
                      {" "}
                      <a href="mailto:reactacademy@globaltrendz.com">
                        reactacademy@globaltrendz.com
                      </a>
                    </li>
                    <li>
                      <a href="/enroll">
                        <button class="btn btn-danger">Enroll Now</button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <a href="javascript:void(0)" class="back_to_top">
            <i class="fa fa-angle-up"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Reactjs;
