import React from "react"
import { HashRouter, Route } from "react-router-dom"
import App from './App'
import Topbar from './topbar'
import Contact from './contact'
import Enroll from './enroll'
import Reactjs from './reactjs'
import ReactjsAdvanced from './reactjsAdvanced'
import ReactjsFullstack from './reactjsFullStack'
import ReactNative from './reactnative'
import StudentRegistrationForm from './StudentRegistrationForm'
import privacy from './privacy_policy'


const Routes = () => {
  return (
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/" component={Topbar} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/enroll' component={Enroll} />
      <Route exact path='/react-js-training-course' component={Reactjs} />
      <Route exact path='/react-js-advanced-course' component={ReactjsAdvanced} />
      <Route exact path='/react-js-fullstack-course' component={ReactjsFullstack} />
      <Route exact path='/react-native-training-course' component={App} />
      <Route exact path='/student-registration-form' component={StudentRegistrationForm} />
      <Route exact path='/privacy-policy' component={privacy} />
    </div>
  )
}

export default Routes