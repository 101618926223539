
var urlString = "https://globaltrendz.info/gt-api/";
// var urlString = "https://192.227.69.95:8047/";

const PostApiCall = {
  postRequest(userData, url) {


    return fetch(urlString + url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),

    })
      .then(response => {
        // console.log(response)
        return (response)

      })
      .catch(error => {
        console.log('request failed', error);
        return error;
      });
  },
};

export default PostApiCall;