
//var urlString = "http://www.globaltrendz.website/eguru/";

//  var urlString = "http://localhost:4000/";
var urlString = "https://globaltrendz.info/gt-api/";

const GetApiCall = {
  getRequest(url) {

    return fetch(urlString + url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // console.log(response)
        return (response)

      })
      .catch(error => {
        console.log('request failed', error);
        return error;
      });
  },
};

export default GetApiCall;
